import { getServerSession } from "../utils/server-session";
import { Button, Sheet } from "@mui/joy";
import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import { GetServerSideProps } from "next";
import { signIn } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const translationProps = await serverSideTranslations(ctx.locale ?? "nl", [
    "common",
    "sign-in",
  ]);

  const { error } = ctx.query;

  const session = await getServerSession(ctx);
  if (!error && session) {
    return {
      redirect: { destination: "/" },
      props: {},
    };
  }

  return {
    props: {
      ...translationProps,
    },
  };
};

export default function SignIn() {
  const { t } = useTranslation(["common", "sign-in"]);
  const { query } = useRouter();

  const callbackUrl = transformCallbackUrl(
    Array.isArray(query.callbackUrl) ? query.callbackUrl[0] : query.callbackUrl,
  );

  useEffect(() => {
    if (!query.error) {
      void signIn("openid-connect", {
        callbackUrl,
      });
    }
  }, [callbackUrl, query.error]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        p: 2,
      }}
    >
      <Head>
        <title>{t("sign-in:SignIn")}</title>
      </Head>
      {query.error ? (
        <Sheet
          invertedColors
          variant="soft"
          color="danger"
          sx={{
            px: {
              lg: 24,
              md: 8,
              xs: 4,
            },
            py: {
              lg: 16,
              md: 6,
              xs: 4,
            },
            borderRadius: "sm",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 4,
            }}
          >
            <Typography
              level="h3"
              component="h1"
              sx={{
                color: "inherit",
              }}
            >
              {t("sign-in:SignInFailed")}
            </Typography>
            <Typography
              level="body-md"
              component="p"
              sx={{
                color: "inherit",
                maxWidth: "350px",
              }}
            >
              {t("sign-in:SignInFailedMessage")}
            </Typography>
            <Button
              onClick={() => {
                void signIn("openid-connect", {
                  callbackUrl,
                });
              }}
            >
              {t("sign-in:TryAgain")}
            </Button>
          </Box>
        </Sheet>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

function transformCallbackUrl(callbackUrl: string = "/") {
  // If the callback URL is the sign-in page, redirect to the home page to
  // prevent infinite redirects.
  if (callbackUrl.includes("sign-in")) {
    return "/";
  }
  return callbackUrl;
}
